@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600');


.TimeLineHeader {
  font-family: "Cutive Mono", monospace !important;
  font-weight: 400;
  font-style: normal;
  font-size: 24px !important;
  padding-left: 16px;
  text-align: left;
}

.ExperienceContentGrid {
  padding: 3%;
  padding-top: 0%;
  font-family: "Nunito", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  font-size: 20px !important;
  color: black;
}

.bulletContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.slideLeft-0{
  width: 100%;
  animation: 0.5s slideLeft;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
}

.slideLeft-1{
  width: 100%;
  animation: 1s slideLeft;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
}

.slideLeft-2{
  width: 100%;
  animation: 1.5s slideLeft;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
}

@keyframes slideLeft {
  from {
    opacity: 0;
    margin-left: 50%;
  }
  to {
    opacity: 1;
    margin-left: 0%;
  }
}

.Title{
  font-weight: bold;
  color: #421BC4;
}


.SemiTitle{
  font-size: 18px;
}

.Content{
  font-size: 18px;
}

.TagList{
  padding-top: 2%;
  font-size: 14px;
}

.Content ul {
  padding-left: 3%;
}

/* https://codepen.io/wbeeftink */

.tags {
  list-style: none;
  margin: 0;
  overflow: hidden; 
  padding: 0;
}

.tags li {
  float: left; 
}

.tag {
  background: #eee;
  border-radius: 3px 0 0 3px;
  color: #999;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  transition: color 0.2s;
  -webkit-transition: color 0.2s;
}

.tag::before {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.tag::after {
  background: #fff;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #eee;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
}

.tag:hover {
  background-color: crimson;
  color: white;
}

.tag:hover::after {
   border-left-color: crimson; 
}

.TimeLineIcon:hover{
  color: crimson !important;
}




@media (max-width: 768px) {
  .ExperienceContentGrid{
    /* height: 20vh; */
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    
  }

  .Content{
    display: none;
  }

  .TagList{
    display: none;
  }

  
}