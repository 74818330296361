.profileHeader {
    width: 45%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure space between elements */
    align-items: center;
    padding: 5px;
}

.profilePicture {
    display: block;
    margin: auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.profilePictureBlock1 {
    flex: 1; /* Allow the image block to take available space */
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.9;
    width: 100%;
}

.profilePictureBlock2 {
    flex: 1; /* Allow the title and footer block to take available space */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure space between elements */
    align-items: center;
    width: 100%;
}

.profileHeaderImage {
    width: 40%;
    border-radius: 50%;
}

.profileTitle {
    width: 100%;
    text-align: center;
}

.profileTitleText {
    font-family: 'Playwrite ZA', cursive;
    font-size: 55px;
    text-align: center;
    transform: rotate(-5deg);
    color: beige;
    margin: 0;
}

.profileSemiTitle {
    text-align: center;
    color: beige;
    font-family: "Lucida Console", "Courier New", monospace;
    font-size: 20px;
    padding-top: 5px;
}

.profileFooter {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.largeIcon {
    font-size: 60px !important;
    color: white;
    padding: 6%;
}



@media (max-width: 768px) {
    .profileTitleText {
        font-size: 30px; /* Decrease font size on smaller screens */
    }

    .largeIcon {
        font-size: 40px !important;
    }

    .profileHeaderImage {
        height: 210px;
        width: 210px;
    }

    .profileSemiTitle {
        font-size: 11.5px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .profileTitleText {
        font-size: 30px; 
    }

    .largeIcon {
        font-size: 40px !important;
    }

    .profileHeaderImage {
        height: 210px;
        width: 210px;
    }

    .profilePictureBlock1 {
        height: 80%;
    }
}

@media only screen and (min-width: 1440px) and (max-width: 1660px) {
    .profileTitle {
        height: 40%;
    }

    .profileFooter {
        height: 60%;
    }
}
