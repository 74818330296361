.AboutMeTitleGrid {
  padding: 3%;
}

.AboutMeContentGrid {
  padding-top: 3%;
  padding-left: 10%;
  padding-right: 10%;
}

.AboutMeStatsContainer {
  padding: 3%;
  text-align: center;
  /* border: 1px solid beige; */
  min-height: 15vh;
}

.AboutMeHeader {
  font-family: "Courier Prime", monospace;
  font-weight: bolder;
  font-style: normal;
  font-size: 38px;
  text-align: center;
  color: black;
}

.AboutMeBody {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}

.listItem {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}

.listKey {
  font-weight: 800;
}

.StatIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 6px solid black;
  border-radius: 10%;
  font-size: 40px;
  font-family: "Nunito", sans-serif;
  font-weight: 900;
  margin: 15%;
}

.StatInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 20px;
  line-height: 2em;
}

.square {
  width: 100px; /* Set a fixed width */
  height: 100px; /* Set a fixed height equal to the width */
  border: 2px solid black; /* Add a border */
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box; /* Include border in the element's dimensions */
  margin: auto; /* Center the square within its container */
}

.AboutMeParallax {
  background-color: #faf9f6;
  background-image: url("../../assets/images/Marven_BG.jpg");

  min-height: 40vh;
  color: white;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 767px) {
  .AboutMeBody {
    font-size: 16px;
    height: 40vh;
  }

  .listItem {
    font-size: 12px;
  }

  .ListItem {
    padding: 0%;
  }

  .AboutMeParallax {
    display: none;
  }

  .AboutMeHeader{
    font-size: 28px;
  }
}

@media only screen and (min-width:768px) and (max-width:1280px){
  .AboutMeBody {
    font-size: 20px;
    height: 25vh;
  }

  .listItem {
    font-size: 14px;
  }

  .ListItem {
    padding: 0%;
  }

  .AboutMeParallax {
    display: none;
  }

  .AboutMeHeader{
    font-size: 28px;
  }
}

@media only screen and (min-width:1281px) { /* hi-res laptops and desktops */ 
  .AboutMeParallax {
    display: block;
  }
}
